import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form', {focus: false})
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()

  // Workaround https://github.com/twbs/bootstrap/issues/16516
  $(document).on('show.bs.dropdown', function (event) {
    $('.dropdown-backdrop').off().remove()
  })
})
